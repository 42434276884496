body{
    --course_bg: #CBEB96;
    --course_border: #A7C970;
}

a{
    text-decoration: none;
}

.pageWrapper{
    width: 1280px;
    padding-top: 111px;
    display: flex;
    flex-direction: column;
}

.burgerLinks{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.burgerLinks a{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    transition: 300ms;
    width: 100%;
    padding: 18px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.heroBlock{
    display: flex;
    gap: 12px;
    width: 100%;
    height: 572px;
}

.heroMain{
    border-radius: 22px;
    padding: 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--course_bg);
    width: 100%;
}

.headingWrapper{
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.headingWrapper h1{
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 82px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}

.headingWrapper p{
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.headingWrapper span{
    color: rgba(0, 0, 0, 0.60);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
    text-decoration-style: solid;
    text-decoration-line: strikethrough;
}


.heroButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #222222;
    padding: 26px;
    border-radius: 40px;
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 300ms;
}

.heroButton:hover{
    transform: scale(1.05);
}

.heroSecondary{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
}

.heroImageWrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 22px;
}

.heroImageWrapper img{
    width: 100%;
    height: auto;
}

.heroBlockWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.secondaryBlock{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    border-radius: 22px;
    width: 100%;
    padding: 64px 0px;
    overflow: hidden;
}

.secondaryText{ 
    position: relative;
    z-index: 10;
    margin: 0;
}

.secondaryTextHidden{
    margin: 0;
    position: absolute;
    margin: auto;
    z-index: 7;
    padding: 6px;
    color: #F8F8F8;
    background: #F8F8F8;
}

.backgroundCircle{
    position: absolute;
    margin: auto;
    height: 180%;
    width: auto;
    z-index: 6;
}

.backgroundBlackStar{
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 8;
}

.backgroundStar{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.lightSection{
    display: flex;
    padding-top: 120px;
    width: 100%;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.cardsGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    grid-auto-rows: minmax(100px, auto);
}

.aboutCard{
    display: flex;
    flex-direction: column;
    gap: 26px;
    border-radius: 22px;
    background: #F8F8F8;
    width: 100%;
    padding: 34px;
    border: 1px solid rgba(0,0,0,0);
    transition: 300ms;
}

.halfColorCard{
    display: flex;
    flex-direction: column;
    gap: 26px;
    border-radius: 22px;
    background: rgba(203, 235, 150, 0.20);
    width: 100%;
    padding: 34px;
    border: 2px solid var(--course_border);
    transition: 300ms;
}

.aboutCard:hover{
    border: 1px solid rgba(0,0,0,0.16)
}

.aboutCardContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.aboutCardContent h3{
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.aboutCardContent p{
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bigCard{
    background: var(--course_bg);
    position: relative;
    padding: 34px;
    border-radius: 22px;
    width: 100%;
}

.aboutDoctorsSwitcher{
    display: none;
}

.bigCard h1{
    margin: 0;
    padding: 0;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 2;
}

.bigCardStar{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.fullWidthButton{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 26px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #202020;
    width: 100%;
    border-radius: 40px;
    transition: 300ms;
}

.fullWidthButton:hover{
    background: #202020;
    color: #FFFFFF;
}

.colorSection{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    width: 100%;
    padding: 80px 34px;
    border-radius: 22px;
    margin-top: 120px;
    background: var(--course_bg);
}

.colorCard{
    padding: 34px;
    border-radius: 22px;
    background: rgba(255,255,255,0.8);
}

.colorCard p{
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ourDoctors{
    padding-top: 120px;
    display: flex;
    gap: 8px;
    height: 820px;
}

.aboutDoctor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px;
    background: #F8F8F8;
    border-radius: 22px;
    height: 100%;
    width: 100%;
}

.ourDoctorsText{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nextButton{
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #202020;
    cursor: pointer;
    transition: 300ms;
}

.nextButton:hover{
    background: #202020;
    transform: scale(1.1);
}

.nextButton:hover path{
    transition: 300ms;
    color: #fff;
    fill: #fff;
}

.doctorImageWrapper{
    min-width: 636px;
    height: 100%;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
}

.doctorImage{
    width: 100%;
    border-radius: 22px;
}

.switcher{
    position: absolute;
    right: 34px;
    bottom: 34px;
    display: flex;
    gap: 22px;
    padding: 12px 22px;
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(10px);
    border-radius: 26px;
}

.aboutDoctorsSwithger{
    display: none;
}

.switcherButton{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #202020;
}

.switcherButton{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #202020;
    transition: 300ms;
}

.switcherButton:hover path{
    stroke: #FFF;
    transition: 300ms;
}

.switcherButton:hover{
    background: #202020;
    transform: scale(1.1);
}

.doctorSwitched{
    animation-duration: 800ms;
    animation-name: showDoctor;
}

@keyframes showDoctor {
    from {
        transform: scale(1.05);
        opacity: 0.6;
    }
  
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.messages{
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.firstMessage{
    display: flex;
    padding: 22px 36px;
    background: #FFFFFF;
    width: fit-content;
    border-radius: 32px;
    position: relative;
}

.firstMessageCorner{
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.doctorMessageWrapper{
    display: flex;
    width: 100%;
    justify-content: end;
}

.doctorMessage{
    display: flex;
    flex-direction: column;
    padding: 22px 36px;
    background: #CBEB96;
    width: fit-content;
    border-radius: 32px;
    position: relative;
    transition: 300ms;
}

.doctorName{
    color: #202020;
    font-size: 18px;
    font-family: 'Suisse Intl Medium';
    font-style: normal;
    line-height: 132%;
    padding: 0;
    margin: 0;
}

.doctorMessageText{
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.secondMessageCorner{
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.questionsBlock{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    align-items: center;
    padding: 80px 34px;
    border-radius: 22px;
    background: #F8F8F8;
}

.questionsBlock h1{
    margin: 0;
    padding: 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.questionsBlockCards{
    display: flex;
    gap: 12px;
    width: 100%;
}

.questionsBlockCard{
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    width: 100%;
    border-radius: 22px;
    background: #FFFFFF;
    padding: 34px;
}

.questionsBlockCard p{
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.questionsBlockCard a{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    color: #202020;
    padding: 16px;
    border-radius: 25px;
    border: 1px solid #202020;
    width: 100%;
}

.questionsBlockCard a:hover{
    background: #202020;
    color: #FFFFFF;
}
