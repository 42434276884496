.pageWrapper{
    width: 1280px;
    padding-top: 111px;
}

.burgerLinks{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.burgerLinks a{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    transition: 300ms;
    width: 100%;
    padding: 18px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.mainTitle{
    color: #202020;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 112%;
    padding: 0;
    margin: 0;
}

.heroWrapper{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}


.subtitle{
    padding: 8px 20px;
    background: #F0F0F0;
    line-height: 110%;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
    width: fit-content;
}

.coursesList{
    margin-top: 40px;
    display: flex;
    gap: 12px;
    width: 100%;
}

.course{
    width: 100%;
    padding: 22px;
    border-radius: 22px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    cursor: pointer;
    transition: 300ms;
    border: 1px solid transparent;
}

.course:hover{
    border: 1px solid rgba(0, 0, 0, 0.20);
}

.courseImage{
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 12px;
}
.courseInfo{
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.courseInfo h2{
    color: #202020;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    padding: 0;
    margin: 0;
}

.courseInfo p{
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: auto;
    padding: 0;
    margin: 0;
}

.button{
    margin-top: 12px;
    padding: 22px 36px;
    color: #202020;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: auto;
    background: transparent;
    background: #FFFFFF;
    border-radius: 50px;
    width: 100%;
}