.pageWrapper{
    width: 1280px;
    padding-top: 111px;
}

.mobileOnly{
    display: none !important;
}

.burgerLinks{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.burgerLinks a{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    transition: 300ms;
    width: 100%;
    padding: 18px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

a{
    cursor: pointer;
}

h1{
    color: #202020;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 112%;
    padding: 0;
    margin: 0;
}

h2{
    color: #202020;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.rowFlex{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.columnFlex{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.heroMainBlock{
    display: flex;
    flex-direction: column;
    height: 274px;
    padding: 34px;
    background: #F8F8F8;
    border-radius: 22px;
    justify-content: space-between;
}

.heroMainBlock a{
    color: #202020;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.heroRecordBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 205px;
    padding: 34px;
    border-radius: 22px;
    background: #AAB1FF;
}

.whiteButton{
    display: flex;
    padding: 32px;
    background: #FFFFFF;
    color: #202020;
    text-decoration: none;
    border-radius: 52px;
    transition: 300ms;
}

.whiteButton:hover{
    transform: scale(1.05);
}

.firstHeroImage{
    width: 314px;
    border-radius: 22px;
}

.secondHeroImage{
    width: 314px;
    border-radius: 146px;
}

.thirdHeroImage{
    width: 636px;
    border-radius: 22px;
}

.aboutBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    padding-top: 120px;
}

.aboutText{
    color: #202020;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%; /* 60.72px */
    padding: 0;
    margin: 0;
}

.aboutButton{
    padding: 32px;
    border: 1px solid #202020;
    border-radius: 52px;
    transition: 300ms;
    text-decoration: none;
    color: #202020;
}

.aboutButton:hover{
    background: #202020;
    color: #FFFFFF;
}

.servicesBlock{
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 120px;
    align-items: center;
}

.servicesGrid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

.servicesGrid div{
    aspect-ratio: 1;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px;
    transition: 300ms;
    background-position: center;
    background-size: 100%;
    transition: 400ms;
    cursor: pointer;
}

.servicesGrid div:hover{
    background-size: 120%;
}

.servicesGrid div h3{
    color: #202020;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.servicesGrid div a{
    padding: 22px 32px;
    border-radius: 52px;
    background: #FFFFFF;
    color: #202020;
    text-decoration: none;
    width: fit-content;
    transition: 300ms;
}

.servicesGrid div a:hover{
    transform: scale(1.05);
}

.servicesGrid div.buttonRow{
    width: 100%;
    display: flex;
    align-items: end;
    aspect-ratio: 0;
    padding: 0px;
}

.service1{
    background: #B1D5F7;
    background-image: url("../../assets/6.png");
    background-size: cover;
}

.service2{
    
}

.service3{
    background: #79BAB7;
    background-image: url("../../assets/3.png");
    background-size: cover;
}

.service4{
    background: #AAB1FF;
    background-image: url("../../assets/4.png");
    background-size: cover;
}

.service5{
    padding: 0 !important;
    padding-top: 34px !important;
}

.service5 p{
    padding: 0;
    margin: 0;
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.service6{
    background: #75BBA6;
    background-image: url("../../assets/1.png");
    background-size: cover;
}

.service7{
    border-radius: 50% !important;
    border: 1px solid #2F2F2F;
    align-items: center;
    justify-content: center !important;
    gap: 22px;
}

.service7 a{
    border: 1px solid #202020;
}

.service8{
    background: #CBEB96;
    background-image: url("../../assets/8.png");
    background-size: cover;
}

.service9{
    background: #F7B458;
    background-image: url("../../assets/9.png");
    background-size: cover;
    
}

.service9 h3{
    min-width: 260px;
}

.service10{
    
}

.service11{
    background: #EFA8A9;
    background-image: url("../../assets/11.png");
    background-size: cover;
}

.service12{
    background: #B9D7F7;
    background-image: url("../../assets/12.png");
    background-size: cover;
}

.ourDoctors{
    padding-top: 120px;
    display: flex;
    gap: 8px;
    height: 820px;
}

.aboutDoctor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px;
    background: #F8F8F8;
    border-radius: 22px;
    height: 100%;
    width: 100%;
}

.ourDoctorsText{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nextButton{
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #202020;
    cursor: pointer;
    transition: 300ms;
}

.nextButton:hover{
    background: #202020;
    transform: scale(1.1);
}

.nextButton:hover path{
    transition: 300ms;
    color: #fff;
    fill: #fff;
}

.doctorImageWrapper{
    min-width: 636px;
    height: 100%;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
}

.doctorImage{
    width: 100%;
    border-radius: 22px;
}

.switcher{
    position: absolute;
    right: 34px;
    bottom: 34px;
    display: flex;
    gap: 22px;
    padding: 12px 22px;
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(10px);
    border-radius: 26px;
}

.aboutDoctorsSwithger{
    display: none;
}

.switcherButton{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #202020;
}

.switcherButton{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #202020;
    transition: 300ms;
}

.switcherButton:hover path{
    stroke: #FFF;
    transition: 300ms;
}

.switcherButton:hover{
    background: #202020;
    transform: scale(1.1);
}

.doctorSwitched{
    animation-duration: 800ms;
    animation-name: showDoctor;
}

@keyframes showDoctor {
    from {
        transform: scale(1.05);
        opacity: 0.6;
    }
  
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.messages{
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.firstMessage{
    display: flex;
    padding: 22px 36px;
    background: #FFFFFF;
    width: fit-content;
    border-radius: 32px;
    position: relative;
}

.firstMessageCorner{
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.doctorMessageWrapper{
    display: flex;
    width: 100%;
    justify-content: end;
}

.doctorMessage{
    display: flex;
    flex-direction: column;
    padding: 22px 36px;
    background: #CBEB96;
    width: fit-content;
    border-radius: 32px;
    position: relative;
    transition: 300ms;
}

.doctorName{
    color: #202020;
    font-size: 18px;
    font-family: 'Suisse Intl Medium';
    font-style: normal;
    line-height: 132%;
    padding: 0;
    margin: 0;
}

.doctorMessageText{
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.secondMessageCorner{
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.programsSection{
    display: flex;
    height: 200vh;
    position: relative;
    margin-top: 60px;
}


.programsBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 42px;
    height: 100vh;
    padding-top: 60px;
    padding-bottom: 60px;
    /* padding-bottom: 0px; */
    width: 100%;
    position: sticky;
    top: 0;
}

.programsHeading{
    display: flex;
    width: 100%;
    justify-content: center;
    white-space: pre;
}

.programTypeActive{
    cursor: pointer;
    border-bottom: 3px solid #000000;
}

.programTypeDisabled{
    cursor: pointer;
    opacity: 0.4;
    transition: 300ms;
}

.programsContentImage{
    width: 400px;
    height: 400px;
}

.aboutProgram{
    display: flex;
    color: #202020;
    font-size: 32px;
    font-style: normal;
    gap: 22px;
    align-items: center;
    font-weight: 400;
    line-height: 132%;
    padding: 34px;
    height: 246px;
    border-radius: 22px;
    background: #CBEB96;
}

.aboutProgram p{
    padding: 0;
    margin: 0;
}

.programsContentWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.programsContent{
    display: flex;
    flex-direction: row;

}

.clickToSwitch{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.programsList{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 670px;
    max-width: 670px;
}

.programsPair{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}

.program{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 32px 0px;
    border-radius: 43px;
    background: #F8F8F8;
    border: 2px solid #F8F8F8;
    cursor: pointer;
    transition: 300ms;
}

.activeProgram{
    border: 2px solid #A7C970;
    background: #CBEB96;
}

.aboutProgramButtons{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.aboutProgramButtons .button{
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 32px;
    width: 236px;
    border-radius: 42px;
    border: 1px solid #000;
    text-decoration: none;
}

.program2{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 32px 0px;
    border-radius: 43px;
    background: #F8F8F8;
    border: 2px solid #F8F8F8;
    cursor: pointer;
    transition: 300ms;
}

.activeProgram2{
    border: 2px solid #9BBAD9;
    background: #B9D8F7;
}

.reviews{
    padding-top: 120px;
    display: flex;
    gap: 8px;
    width: 100%;
    height: 820px;
}

.rightText{
    width: 100%;
    text-align: end;
    font-size: 36px;
}

.reviews_left{
    display: flex;
    flex-direction: column;
    gap: 46px;
    padding: 34px;
    background: #B9D8F7;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    background-image: url(../../assets/reviews.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.reviews_right{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.reviewButton{
    display: flex;
    padding: 22px 32px;
    border: 1px solid #202020;
    border-radius:  32px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: #202020;
}

.reviewButton:hover{
    color: #FFFFFF;
    background: #202020;
}

.subblock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.review{
    background: #F8F8F8;
    border-radius: 22px;
    display: flex;
    padding: 0px 34px;
    gap: 22px;
    align-items: center;
    height: 100%;
}

.reviewText{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.reviewTextName{
    padding: 0;
    margin: 0;
    color: #202020;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.reviewTextText{
    padding: 0;
    margin: 0;
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.map{
    border-radius: 22px;
    border: 2px solid #ffffff;
}

.aboutDoctorsSwitcher{
    display: none;
}

.aboutProgram a:hover{
    background: #202020;
    color: #FFFFFF;
}

@media (max-width: 767px){
    .mobileOnly{
        display: flex !important;
    }
    .desctopOnly{
        display: none !important;
    }

    .pageWrapper{
        width: 90%;
    }

    .columnFlex{
        width: 100%;
    }

    .columnFlexMobile{
        width: 82%;
    }

    .heroMainBlock{
        height: auto;
        gap: 12px;
        padding: 22px;
    }

    h1{
        font-size: 28px;
    }

    .heroMainBlock a{
        font-size: 18px;
    }

    .secondHeroImage{
        width: 100%;
        height: auto;
    }

    .firstHeroImage{
        width: 100%;
        height: auto;
    }

    .thirdHeroImageWrapper{
        width: 100%;
    }

    .thirdHeroImage{
        width: 100%;
        height: auto;
    }

    .heroRecordBlock{
        height: auto;
        flex-direction: column;
        align-items: start;
        gap: 12px;
        padding: 22px;
    }

    .heroRecordBlock h2{
        font-size: 24px;
    }

    .heroRecordBlock .whiteButton{
        width: 100%;
    }

    .whiteButton{
        padding: 22px 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aboutText{
        font-size: 24px;
    }

    .aboutButton{
        padding: 22px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .aboutBlock{
        padding-top: 60px;

    }

    .servicesBlock{
        padding-top: 60px;
        gap: 22px;
    }


    .servicesGrid{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    .servicePair{
        aspect-ratio: 0 !important;
        display: flex;
        flex-direction: row !important;
        gap: 8px;
        width: 100%;
        height: fit-content !important;
    }

    .servicesGrid div{
        padding: 0px;
        height: 100%;
        border-radius: 16px 0px 0px 16px;
    }
    .servicesGrid div h3{
        color: #202020;
        font-size: 16px;
    }
    .servicesGrid div a{
        padding: 16px 22px;
        font-size: 14px;
    }

    .service2{
        display: none;
    }

    .service5{
        padding: 12px !important;
        aspect-ratio: 0 !important;
    }

    .service7{
        aspect-ratio: 0 !important;
        padding: 32px 0px !important;
        gap: 12px !important;
        width: 100%;
        height: 160px;
        border-radius: 16px !important;
    }

    .service5 p{
        font-size: 14px;
    }

    .service{
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: start !important;
        height: 140px !important;
        gap: 16px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 16px !important;
        aspect-ratio: 0 !important;
    }

    .service h3{
        height: fit-content !important;
        font-size: 16px !important;
        word-wrap: normal;
    }

    .buttonRow{
        align-items: center !important;
        padding: 0px 16px 16px 16px !important;
    }

    .buttonRow .button{
        border: 1px solid #202020;
        width: 100%;
        padding: 12px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ourDoctors{
        padding-top: 60px;
        flex-direction: column;
        height: auto;
    }

    .doctorImageWrapper{
        width: 100%;
        aspect-ratio: 1;
        min-width: unset;
    }

    .doctorImage{
        width: 100%;
        min-height: 100%;
    }

    .aboutDoctor{
        padding: 22px;
        height: auto;
        justify-content: top;
        gap: 22px;
    }

    .firstMessage{
        font-size: 16px;
        padding: 16px 22px;
    }

    .doctorMessage{
        padding: 16px 22px;
        max-width: 95%;
    }

    .doctorName{
        font-size: 16px;
    }

    .doctorMessageText{
        font-size: 16px;
    }

    .ourDoctorsText{
        display: none;
    }

    .messages{
        gap: 12px;
    }
    
    .aboutDoctorsSwitcher{
        display: flex;
        gap: 8px;
        padding: 8px 8px;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .aboutDoctorsSwitcher .switcherButton{
        width: 40px;
        height: 40px;
    }

    .aboutDoctorHeading{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .programsBlock{
        height: auto;
        gap: 32px;
    }

    .programsSection{
        height: auto;
    }

    .programsHeading{
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        gap: 8px;
    }

    .programsHeadingButtons{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;    
        gap: 8px
    }

    .programTypeActive{
        opacity: 1;
    }

    .programTypeDisabled{
        opacity: 0.6;
    }

    .programsContentImage{
        display: none;
    }
    
    .programsContentWrapper{
        flex-direction: column;
        gap: 12px;
    }

    .programsList{
        width: 100%;
    }

    .clickToSwitch{
        flex-direction: row-reverse;
    }

    .clickToSwitch svg{
        transform: rotate(90deg);
    }

    .aboutProgram{
        border-radius: 22px;
        padding: 22px;
        height: auto;
        flex-direction: column;
    }

    .aboutProgram p{
        font-size: 22px;
        line-height: normal;
    }

    .aboutProgramButtons{
        width: 100%;
    }

    .aboutProgram a{
        width: 100% !important;
        padding: 22px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .program{
        border-radius: 22px;
        padding: 22px 0px;
    }
    
    .program2{
        border-radius: 22px;
        padding: 22px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .rightText{
        font-size: 18px;
        padding: 0px;
        margin: 0px;
    }

    .reviews{
        padding-top: 60px;
        flex-direction: column;
        height: auto;
    }

    .reviews_left{
        border-radius: 22px;
        padding: 22px;
        gap: 0px;
        height: 450px;
    }

    .subblock{
        flex-direction: column;
        align-items: start;
    }

    .reviewButton{
        width: 100%;
        padding: 22px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .review{
        flex-direction: column;
        padding: 22px;
        gap: 12px;
        border-radius: 22px;
        align-items: start;
    }

    .review img{
        height: 80px;
        width: 80px;
    }

    .reviewTextName{
        font-size: 16px;
    }

    .reviewTextText{
        font-size: 16px;
    }

    .map{
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .switcher{
        right: 12px;
        bottom: 12px;
    }

    .service_info{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: start !important;
        gap: 8px;
    }

    .service_info a{
        padding: 0px !important;
        align-items: start;
        text-decoration: underline !important;
    }

    .service_info a:hover{
        transform: scale(1) !important;
    }

}