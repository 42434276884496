@font-face {
  font-family: 'Suisse Intl';
  src: url(./assets/font/SuisseIntl.otf);
}

@font-face {
  font-family: 'Suisse Intl Medium';
  src: url(./assets/font/SuisseIntlMedium.otf);
}

*{
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#root{
  font-family: 'Suisse Intl', 'Roboto';
  font-size: 18px;
  margin: 0;
  padding: 0;
}

body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.appWrapper{
  width: 100vw;
  display: flex;
  justify-content: center;
}
