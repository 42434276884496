.footerContent{
    margin-top: 120px;
    border-top: 1px solid rgba(0,0,0,0.2);
    display: flex;
    width: 1280px;
    justify-content: space-between;
    align-items: start;
    padding: 80px 0px;
}

.footerLinks{
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.logo{
    height: 50px;
    width: auto;
}


.footerLinks a{
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 0px;
    text-decoration: none;
    transition: 300ms;
}

.footerLinks a:hover{
    transform: scale(1.05);
}

.footerButtons{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.footerButtons a{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    transition: 300ms;
    color: #202020;
}

.footerButtons a:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    .logo{
        height: 40px;
    }

    .footerContent{
        margin-top: 60px;
        flex-direction: column-reverse;
        width: 100%;
        padding: 30px 0px;
        align-items: center;
        gap: 22px;
    }

    .footerButtons{
        gap: 4px;
    }
}
