.navbarWrapper{
    display: flex;
    width: 100vw;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    transition: 300ms;
}

.logo{
    cursor: pointer;
    height: 50px;
    width: auto;
}

.navbar{
    display: flex;
    width: 1280px;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
}

.navbarHide{
    top: -110px;
}

.navbarLinks{
    display: flex;
    gap: 32px;
}

.navbarLinks a{
    color: #202020;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px;
    text-decoration: none;
    transition: 300ms;
}

.navbarLinks a:hover{
    transform: scale(1.05);
}

.navButtons{
    display: flex;
    gap: 22px;
    align-items: center;
}

.navButtons a{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    transition: 300ms;
}

.navButtons a:hover{
    transform: scale(1.05);
}

.navbarButton{
    font-family: 'Suisse Intl';
    border: none;
    display: flex;
    font-weight: 400;
    padding: 16px 22px;
    border-radius: 26px;
    background-color: #3A83F7;
    color: #FFFFFF;
}

.burger{
    display: none;
}

@media (max-width: 767px){

    .navbar{
        width: 90%;
        padding: 12px 0px;
    }

    .logo{
        height: 40px;
    }

    .navButtons{
        display: none;
    }

    .navbarLinks{
        display: none;
    }

    .burger{
        display: flex;
        background-color:transparent;
        border:none;
        height:58px;
        outline:none;
        padding:8px;
        position: relative;
        transition: transform 0.7s cubic-bezier(.25,.8,.25,1);
        width:58px;
    }
      
    .burger span{
        background-color:#999999;
        border-radius:25px;
        display:block;
        height:2px;
        position: absolute;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        width:40px;
    }
    
    .burger span.bar_1{
        top:17px;
        width:40px;
    }
    .burger span.bar_2{
        top:29px;
        width:40px;
    }
    .burger span.bar_3{
        top:41px;
        width:40px;
    }
    
    .burger.expanded{
        display: flex;
        height:58px;
        transform:rotate(180deg);
        transition: all 0.7s cubic-bezier(.25,.8,.25,1);
        width:58px;
    }
    
    .burger.expanded span.bar_1{
        left: 18px;
        top: 18px;
        transform-origin: left;
        transform:rotate(45deg);
        width:30px;
        background-color:#172034;
    }
    .burger.expanded span.bar_2{
        opacity:0;
    }
    .burger.expanded span.bar_3{
        left:18px;
        top:40px;
        transform-origin: left;
        transform:rotate(-45deg);
        width:30px;
        background-color:#555555;
    }
}